import React from 'react';
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import {NavLink} from "react-router-dom";

import Logout from "../../../containers/Auth/Logout/Logout";
import NavigationItem from "../NavigationItem/NavigationItem";
import ExternalNavigationItem
  from "../ExternalNavigationItem/ExternalNavigationItem";

import './userNavigationItems.scss';

const UserNavigationItems = props => {
  const { t } = useTranslation('forms');
  const classes = ["navigationItems"];

  return (
    <Nav as="ul" className={classes.join(' ')}>
      {props.isAuthenticated ?
        <>
          <NavigationItem
            link={t("routes:/notifications")}
            iconName="notifications"
          >{t('links:Notifications')}</NavigationItem>

          <ExternalNavigationItem
            link={props.hostShm}
            target="_blank"
            iconName="help"
          >{t('links:Online help')}</ExternalNavigationItem>

          <Dropdown as="li" className="navigationItem nav-item">
            <Dropdown.Toggle variant="secondary" id="dropdown-account" className="nav-link nav-iconized">
              <i className="material-icons">account_circle</i><span>{t("links:Account")}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right">
              <Dropdown.Item as={NavLink} to={t('routes:/my-information')} className="nav-iconized btn btn-link">
                <i className="material-icons">perm_identity</i><span>{t('links:My Profile')}</span>
              </Dropdown.Item>

              <Dropdown.Item as="button" className="btn btn-link">
                <Logout />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </>
        : null
      }
      {!props.isAuthenticated
        ? (
          <>
            <NavigationItem
              link={t("routes:/login")}
              iconName="login"
            >{t("links:Log in")}</NavigationItem>
            <NavigationItem
              link={t("routes:/register")}
              iconName="ballot"
            >{t("links:Register")}</NavigationItem>
            <NavigationItem
              link={t("routes:/password-reset")}
              iconName="security"
            >{t("links:Forgot password")}</NavigationItem>
          </>
        )
        : null
      }
    </Nav>
  );
};

const mapStateToProps = state => {
  return {
    hostShm: state.app.hostShm,
    documentationUrl: state.app.documentationUrl,
    isAuthenticated: state.auth.token !== null,
  }
}

UserNavigationItems.propTypes = {
  hostShm: PropTypes.string,
  documentationUrl: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps)(UserNavigationItems);
