import React from "react";
import {Route, Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

const PrivateRoute = props => {
  const {t} = useTranslation('routes')
  const {children, token, expirationDate, ...rest} = props;

  let action = <Route {...rest}>{children}</Route>;
  const getActionByAuthState = (token, expirationDate) => {
    const expirationDateTime = new Date(expirationDate);
    if (token === null || expirationDateTime <= new Date()) {
      // action = <Redirect to={t("/login")} />
      action = <Route {...rest} render={<Redirect to={t("/login")} />} />
    }
    return action;
  }

  return (
    <>
      {getActionByAuthState(token, expirationDate)}
    </>
  );
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    expirationDate: state.auth.expirationDate,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
