import React from 'react';
import Nav from "react-bootstrap/Nav";
import { NavLink } from 'react-router-dom';

import './navigationItem.scss';
import PropTypes from "prop-types";

const navigationItem = props => {
  const liClasses = ["navigationItem"];
  liClasses.push(props.liClass);

  const linkClasses = ["navigationItemLink", "nav-iconized"];
  linkClasses.push(props.linkClass);

  return (
    <Nav.Item as="li" className={liClasses.join(' ')}>
      <Nav.Link
        as={NavLink}
        to={props.link}
        className={linkClasses.join(' ')}
        exact={props.exact}
      >
        <i className="material-icons">{props.iconName}</i>
        <span>{props.children}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

navigationItem.propTypes = {
  link: PropTypes.string,
  exact: PropTypes.bool,
  iconName: PropTypes.string,
};

export default navigationItem;
